<template>
  <v-row class="match-height">
    <v-col cols="12">
      <v-container>
        <v-row justify="space-around">
          <v-card
            width="800"
            name="cardPerfil"
          >
            <v-card-title class="white--text mt-0 fondo-card">
              <v-avatar size="80">
                <v-icon class="ml-2 white--text">
                  {{ icons.mdiAccountStar }}
                </v-icon>
              </v-avatar>
              <p
                class="ml-8 white--text"
              >
                Perfil de Usuario
              </p>
            </v-card-title>

            <v-card-text>
              <v-row
                class="mb-15 mt-5 ml-3 mr-3 "
              >
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium">Nombre :</span>
                  <span class="text--secondary">{{ nombre }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Apellido:</span>
                  <span class="text--secondary">{{ apellido }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Número de documento :</span>
                  <span class="text--secondary">{{ rut }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Codigo AS-400:</span>
                  <span class="text--secondary">{{ codigoAs400 }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Correo:</span>
                  <span class="text--secondary">{{ correo }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Cargo:</span>
                  <span class="text--secondary">{{ cargo }}</span>
                </v-col>
                <v-col
                  md="6"
                  cols="11"
                >
                  <span class="font-weight-medium me-2">Punto de venta actual:</span>
                  <span class="text--secondary">{{ sucursal }}</span>
                </v-col>
              </v-row>
              <!-- modal editar perfil -->
              <v-row
                justify="end"
                class="mb-3 mr-3"
                name="modalEditPerfil"
              >
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="edit"
                    >
                      Editar Perfil
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="card-header-title">
                      <span class="text-h5 card-header-title-text">Editar Perfil</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form
                          ref="formPerfil"
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                name="nombre"
                                v-model="editnombre"
                                label="Nombre"
                                required
                                :rules="[validators.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                name="apellidos"
                                v-model="editapellido"
                                label="Apellido"
                                required
                                :rules="[validators.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                name="rut"
                                v-model="editrut"
                                label="RUT"
                                dense
                                hide-details
                                :rules="[validators.required, rutValido]"
                                :rut="true"
                                @input="checkRut"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                name="codigoAs400"
                                v-model="editcodigoAs400"
                                label="Codigo AS-400"
                                required
                                :rules="[validators.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                name="correo"
                                v-model="editcorreo"
                                label="Correo"
                                type="email"
                                required
                                :rules="[validators.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue-grey"
                        elevation="2"
                        @click="dialog = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="primary"
                        elevation="2"
                        :loading="loading"
                        :disabled="loading"
                        @click="modificarPerfil"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-col>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiAccountStar, mdiCloseThick } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { required, emailValidator } from '@core/utils/validation'
import { UserServices } from '../service/userService'

export default {
  components: {},
  setup() {
    const formPerfil = ref(null)
    const rut = ref('')
    const nombre = ref('')
    const apellido = ref('')
    const correo = ref('')
    const codigoAs400 = ref('')
    const cargo = ref('')
    const sucursal = ref('')

    const dialog = ref(false)
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const loading = ref(false)

    const editrut = ref('')
    const editnombre = ref('')
    const editapellido = ref('')
    const editcorreo = ref('')
    const editcodigoAs400 = ref('')
    const editcargo = ref('')
    const editsucursal = ref('')

    return {
      formPerfil,
      icons: {
        mdiAccountStar,
        mdiCloseThick,
      },
      dialog,
      rut,
      nombre,
      apellido,
      correo,
      codigoAs400,
      cargo,
      sucursal,
      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      loading,
      editrut,
      editnombre,
      editapellido,
      editcorreo,
      editcodigoAs400,
      editcargo,
      editsucursal,
      validators: {
        required,
        emailValidator,
      },
      rutValido: value => {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(value)) {
          return 'Rut inválido.'
        }

        return true
      },
    }
  },
  created() {
    this.getInforPerfil()
  },
  methods: {
    checkRut(item) {
      // Despejar Puntos
      let valor = this.rut.replace('.', '')
      let valor2 = this.editrut.replace('.', '')
      valor = valor.replace('-', '')

      valor2 = valor2.replace('-', '')
      const cuerpo = valor.slice(0, -1)

      const cuerpo2 = valor2.slice(0, -1)
      let dv = valor.slice(-1).toUpperCase()

      const dv2 = valor2.slice(-1).toUpperCase()

      // Formatear RUN
      this.rut = `${cuerpo}-${dv}`

      this.editrut = `${cuerpo2}-${dv2}`

      // Si no cumple con el mínimo ej. (n.nnn.nnn)
      if (cuerpo.length < 7) {
        this.rut.setCustomValidity('RUT Incompleto')

        return false
      }

      if (cuerpo2.length < 7) {
        this.editrut.setCustomValidity('RUT Incompleto')

        return false
      }

      // Calcular Dígito Verificador
      let suma = 0
      let multiplo = 2

      // Para cada dígito del Cuerpo
      for (let i = 1; i <= cuerpo.length; i++) {
        // Obtener su Producto con el Múltiplo Correspondiente
        const index = multiplo * valor.charAt(cuerpo.length - i)

        // Sumar al Contador General
        suma += index

        // Consolidar Múltiplo dentro del rango [2,7]
        if (multiplo < 7) {
          multiplo += 1
        } else {
          multiplo = 2
        }
      }

      // Calcular Dígito Verificador en base al Módulo 11
      const dvEsperado = 11 - (suma % 11)

      // Casos Especiales (0 y K)
      dv = dv == 'K' ? 10 : dv
      dv = dv == 0 ? 11 : dv

      // dv2 = dv2 == 'K' ? 10 : dv2
      // dv2 = dv2 == 0 ? 11 : dv2

      // Validar que el Cuerpo coincide con su Dígito Verificador
      if (dvEsperado !== dv) {
        this.rut.setCustomValidity('RUT Inválido')

        return false
      }

      // if (dvEsperado !== dv2) {
      //   this.rut.setCustomValidity('RUT Inválido')

      //   return false
      // }

      // Si todo sale bien, eliminar errores (decretar que es válido)
      item.setCustomValidity('')
    },
    edit() {
      this.editrut = this.rut
      this.editnombre = this.nombre
      this.editapellido = this.apellido
      this.editcorreo = this.correo
      this.editcodigoAs400 = this.codigoAs400
      this.editcargo = this.cargo
      this.editsucursal = this.sucursal
    },
    async getInforPerfil() {
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      const dataSucursal = JSON.parse(localStorage.getItem('userSucursal')).nombre
      await UserServices.getperfil(dataUser).then(resp => {
        console.log(resp.data.data)
        const informacionPerfil = resp.data.data
        this.rut = informacionPerfil.numeroDocumento
        this.nombre = informacionPerfil.nombres
        this.apellido = informacionPerfil.apellidos
        this.correo = informacionPerfil.correo
        this.codigoAs400 = informacionPerfil.codigoAs400
        this.cargo = informacionPerfil.cargo
        this.sucursal = dataSucursal
        this.checkRut(this.rut)
      })
    },
    async modificarPerfil() {
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo

      const isFormValid = this.formPerfil.validate()
      if (!isFormValid) return
      if (!this.rutValido) return

      const data = {
        nombres: this.editnombre,
        apellidos: this.editapellido,
        numeroDocumento: this.editrut,
        codigoAs400: this.editcodigoAs400,
        correo: this.editcorreo,
      }
      this.loading = true
      await UserServices.putPerfil(dataUser, data).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.loading = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'success'
          this.dialog = false
          this.getInforPerfil()
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loading = false
        }
      })
    },
  },
}
</script>
<style scoped>
.fondo-card {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white;
}
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.card-header-title {
  /* background-color: rgb(20 20 20 / 25%);*/
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2);
  background: linear-gradient(to left, #2f80ed, #56ccf2);
  color: white;
}
.card-header-title-text {
  color: #ffffff;
}
</style>
